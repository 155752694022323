import React from 'react';
import Logo from '../../Common/Logo/Logo';
import SearchBar from '../SearchBar/SearchBar';
import User from '../User/User';
import './Navigation.scss';

interface INavigation {
  handleSearchReport: React.Dispatch<React.SetStateAction<string>>;
}

const Navigation = ({ handleSearchReport }: INavigation) => {
  return (
    <nav className='navigation'>
      <Logo />
      <SearchBar handleSearchReport={handleSearchReport} />
      <div className='navigation__user'>
        <User />
      </div>
    </nav>
  );
};

export default Navigation;
