import { toast } from 'react-toastify';

export const customToast = {
  success(message: string, options = {}) {
    return toast.success(message, {
      ...options,
      style: { backgroundColor: '#4caf50' },
    });
  },
  warning(message: string, options = {}) {
    return toast.warning(message, {
      ...options,
      style: { backgroundColor: '#f68a1c' },
    });
  },
  error(message: string, options = {}) {
    return toast.error(message, {
      ...options,
      style: { backgroundColor: '#d74545' },
    });
  },
};
