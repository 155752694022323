import React, { useEffect } from 'react';
import Logo from '../../Common/Logo/Logo';
import UserModal from '../../Components/UserModal/UserModal';
import { getWorkers, addWorker } from '../../api/Endpoints/Workers';
import { CreateWorkerRequest } from '../../types/Workers/CreateWorkerRequest';
import './WorkersManagement.scss';
import User from '../../Components/User/User';
import { UserWorker } from '../../types/Workers/UserWorker';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../Redux/store';
import {
  updateSingleWorker,
  updateWorkers,
} from '../../Redux/Reducers/WorkersReducer';
import UserTile from '../../Components/UserTile/UserTile';
import { customToast } from '../../Common/CustomToast/CustomToast';

const WorkersManagement = () => {
  const workers = useSelector((state: RootState) => state.workers).workers;
  const dispatch = useDispatch();

  useEffect(() => {
    getWorkers().then((data) => {
      dispatch(updateWorkers(data));
    });
  }, []);

  const addUsers = (name: string, secondname: string, email: string) => {
    const newUser: UserWorker = {
      id: '0',
      name: name,
      surname: secondname,
      email: email,
      hoursReports: [],
    };
    const createUserRequest: CreateWorkerRequest = {
      name: name,
      surname: secondname,
      email: email,
    };
    const newUsers = [...workers, newUser];
    dispatch(updateWorkers(newUsers));

    addWorker(createUserRequest).then((data) => {
      dispatch(updateSingleWorker(data));
      customToast.success('Dodano pracownika');
    });
  };

  const deleteUser = (id: string) => {
    const newUsers = workers.filter((item) => item.id !== id);
    dispatch(updateWorkers(newUsers));
  };

  return (
    <>
      <div className='workers-management__header'>
        <Logo />
        <User />
      </div>
      <div className='workers-management__content'>
        <UserModal handleAddUser={addUsers} />
        <div className='workers-management__list-of-users'>
          {workers.map((userWorker) => (
            <UserTile
              user={userWorker}
              handleDeleteUser={deleteUser}
              key={userWorker.id}
            />
          ))}
        </div>
      </div>
    </>
  );
};

export default WorkersManagement;
