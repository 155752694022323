import React, { useEffect, useState } from 'react';
import './Success.scss';
import { HoursReport } from '../../../types/HoursReports/HoursReport';
import { getNumberOfSignedReports } from '../../../api/Endpoints/HoursReports';

interface ISuccess {
  report: HoursReport;
}

const Success = ({ report }: ISuccess) => {
  const [overdue, setOverdue] = useState(false);
  const [minutes, setMinutes] = useState(0);
  const [hours, setHours] = useState(0);
  const [days, setDays] = useState(0);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [numberOfHoursReports, setNumberOfHoursReports] = useState<number>();

  useEffect(() => {
    getNumberOfSignedReports(report.year, report.month).then((data) => {
      setNumberOfHoursReports(data);
      setIsLoading(false);
    });

    const countDate = new Date(report.deadline).getTime();
    const now = new Date().getTime();
    let difference = countDate - now;

    if (difference <= 0) {
      setOverdue(true);
    }

    difference = Math.abs(difference);

    const newDays = Math.floor(difference / 86400000);
    difference -= newDays * 86400000;

    const newHours = Math.floor(difference / 3600000);
    difference -= newHours * 3600000;

    const newMinutes = Math.floor(difference / 60000);

    setMinutes(newMinutes);
    setHours(newHours);
    setDays(newDays);
  }, []);

  if (isLoading) {
    return <></>;
  }

  return (
    <div className='success'>
      <span className='success__text--bold'>Dziękujemy za wysłanie!</span>
      <div className='success__time'>
        <span>Udało Ci się dostarczyć ewidencję: </span>
        <span
          className={
            overdue ? 'success__time--negative' : 'success__time--positive'
          }
        >
          {days !== 0 && `${Math.abs(days)} dni `}{' '}
          {hours !== 0 && `${Math.abs(hours)}h `}{' '}
          {minutes !== 0 && `${Math.abs(minutes)}m `}
          <span className='success__time-text'>
            {overdue ? 'po czasie' : 'przed czasem'}
          </span>
        </span>
      </div>
      <p className='success__number-text'>
        W tym miesiącu podpisałeś ewidencję jako:
        <span className='success__text--blue'>
          {' ' + numberOfHoursReports}
        </span>
      </p>
      <span className='success__text'>
        {overdue
          ? 'Lepiej pózno, niż wcale. Trzymamy kciuki aby za miesiąc udało Ci się dostarczyć ewidencję godzin przed czasem!'
          : 'Tak trzymaj! Gratulacje!'}
      </span>
    </div>
  );
};

export default Success;
