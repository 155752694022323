import React, { useEffect, useState } from 'react';
import './Report.scss';
import { IconButton } from '@mui/material';
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import CreateReport from '../CreateReport/CreateReport';
import {
  editReport,
  deleteRaport,
  getReports,
} from '../../api/Endpoints/HoursReports';
import ConfirmationDialog from '../../Common/ConfirmationDialog/ConfirmationDialog';
import { HoursReport } from '../../types/HoursReports/HoursReport';
import { UpdateHoursReportRequest } from '../../types/HoursReports/UpdateHoursReportRequest';
import { customToast } from '../../Common/CustomToast/CustomToast';
import { toMonthName } from '../../Functions/ToMonthName';
import { Status } from '../../ConstantsAndEnums/Status';
import { URLParam } from '../../ConstantsAndEnums/URLParams';

interface IReport {
  hoursReport: HoursReport;
  reports: HoursReport[];
  setReports: React.Dispatch<React.SetStateAction<HoursReport[]>>;
}

const Report = ({ hoursReport, reports, setReports }: IReport) => {
  const [overdue, setOverdue] = useState<boolean>(false);
  const [seconds, setSeconds] = useState<number>(0);
  const [minutes, setMinutes] = useState<number>(0);
  const [hours, setHours] = useState<number>(0);
  const [days, setDays] = useState<number>(0);

  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [openEditModal, setOpenEditModal] = useState<boolean>(false);

  const handleEditReport = (
    email: string,
    hours: string,
    year: number,
    month: number,
    deadline: string
  ) => {
    const request: UpdateHoursReportRequest = {
      deadline: new Date(deadline),
      hours: parseFloat(hours),
      newMonth: month,
      newYear: year,
      oldMonth: hoursReport.month,
      oldYear: hoursReport.year,
      workerId: hoursReport.workerId,
      email: hoursReport.email,
    };

    editReport(request).then(() => {
      customToast.success('Zaktualizowano ewidencję');
    });

    const newReports = [...reports];
    const index = newReports.findIndex(
      (item) =>
        item.workerId === hoursReport.workerId &&
        item.year === hoursReport.year &&
        item.month === hoursReport.month
    );
    newReports[index].email = email;
    newReports[index].hours = hours;
    newReports[index].year = year;
    newReports[index].month = month;
    newReports[index].deadline = deadline;

    setReports(newReports);
  };

  const handleDeleteReport = () => {
    setOpenDialog(false);
    setReports((prevState) =>
      prevState.filter(
        (item) =>
          item.workerId !== hoursReport.workerId ||
          item.year !== hoursReport.year ||
          item.month !== hoursReport.month
      )
    );
    deleteRaport(hoursReport.workerId, hoursReport.year, hoursReport.month);
  };

  const getFormattedDatetime = () => {
    return `${
      Math.abs(days) === 1 ? Math.abs(days) + 'dzień' : Math.abs(days) + 'dni'
    } ${Math.abs(hours)}h ${Math.abs(minutes)}m ${Math.abs(seconds)}s`;
  };

  useEffect(() => {
    const interval = setInterval(() => {
      const countDate = new Date(hoursReport.deadline).getTime();
      const now = new Date().getTime();
      let difference = countDate - now;

      if (difference <= 0) {
        setOverdue(true);
      }

      difference = Math.abs(difference);

      const newDays = Math.floor(difference / 86400000);
      difference -= newDays * 86400000;

      const newHours = Math.floor(difference / 3600000);
      difference -= newHours * 3600000;

      const newMinutes = Math.floor(difference / 60000);
      difference -= newMinutes * 60000;

      const newSeconds = Math.floor(difference / 1000);

      setSeconds(newSeconds);
      setMinutes(newMinutes);
      setHours(newHours);
      setDays(newDays);
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [hoursReport.deadline]);

  if (hours === 0 && minutes === 0 && seconds === 0) {
    return <></>;
  }

  return (
    <div className='report'>
      <div className='report__content'>
        <div className='report__status'>
          {hoursReport.isSigned ? (
            <div className='report__status--signed'>Podpisane</div>
          ) : (
            <div
              className={
                overdue ? 'report__status--passed' : 'report__status--pending'
              }
            >
              {overdue ? 'Przekroczone' : 'W trakcie'}
            </div>
          )}
        </div>
        <div className='report__email'>{hoursReport.email}</div>
        <div className='report__month'>{`${toMonthName(hoursReport.month)} ${
          hoursReport.year
        }`}</div>
        <div className='report__hours'>{hoursReport.hours} h</div>
        {hoursReport.isSigned ? (
          <div className='report__signed-datetime'>
            {hoursReport.signedDatetime
              ? new Date(hoursReport.signedDatetime).toLocaleDateString()
              : ''}
          </div>
        ) : (
          <div
            className={
              overdue
                ? 'report__deadline--overdue'
                : 'report__deadline--pending'
            }
          >
            <span className='report__time'>{getFormattedDatetime()}</span>
          </div>
        )}
        <IconButton
          className="'report__edit-icon'"
          onClick={() => setOpenEditModal(true)}
        >
          <CreateOutlinedIcon sx={{ fontSize: 30 }} />
        </IconButton>
        <IconButton
          className='report__delete-icon'
          onClick={() => setOpenDialog(true)}
        >
          <ClearOutlinedIcon sx={{ fontSize: 30 }} />
        </IconButton>

        <ConfirmationDialog
          title='Czy na pewno chcesz usunąć ewidencję?'
          body='Tej akcji nie można cofnąć'
          handleClose={() => setOpenDialog(false)}
          handleConfirm={handleDeleteReport}
          open={openDialog}
        />
        <CreateReport
          open={openEditModal}
          handleClose={() => setOpenEditModal(false)}
          onSubmit={async (
            email: string,
            hours: string,
            year: number,
            month: number,
            deadline: string
          ) => {
            if (year !== hoursReport.year || month !== hoursReport.month) {
              const params = new URLSearchParams([
                [URLParam.Year, year.toString()],
                [URLParam.Month, month.toString()],
                [URLParam.Emails, email],
                [URLParam.Statuses, Status.Signed],
                [URLParam.Statuses, Status.During],
                [URLParam.Statuses, Status.Overdue],
              ]);

              const reports = await getReports(params);

              if (reports.length > 0) {
                customToast.warning('Już dodano za ten miesiąc ewidencję');
                return;
              }
            }
            handleEditReport(email, hours, year, month, deadline);
            setOpenEditModal(false);
          }}
          title='Ewidencja godziny - edycja'
          confirmButtonText='Zapisz'
          emailSelectIsReadOnly={true}
          deadline={hoursReport.deadline}
          email={hoursReport.email}
          hours={hoursReport.hours}
          month={hoursReport.month}
          year={hoursReport.year}
        />
      </div>
    </div>
  );
};

export default Report;
