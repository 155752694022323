import { CreateReportRequest } from '../../types/HoursReports/CreateReportRequest';
import { HoursReport } from '../../types/HoursReports/HoursReport';
import { SentRemarksRequest } from '../../types/HoursReports/SentRemarksRequest';
import { SignHoursReportRequest } from '../../types/HoursReports/SignHoursReportRequest';
import { UpdateHoursReportRequest } from '../../types/HoursReports/UpdateHoursReportRequest';
import { Client } from '../Client';

const controllerName = 'HoursReports';

const getReports = async (
  filters?: URLSearchParams
): Promise<HoursReport[]> => {
  if (filters) {
    return Client('GET', controllerName, {}, filters);
  }
  return Client('GET', controllerName);
};

const getReport = async (year: string, month: string): Promise<HoursReport> => {
  return Client('GET', `${controllerName}/${year}/${month}`);
};

const getNumberOfSignedReports = async (
  year: number,
  month: number
): Promise<number> => {
  return Client('GET', `${controllerName}/${year}/${month}/number`);
};

const addReport = async (body: CreateReportRequest): Promise<HoursReport> => {
  return Client('POST', controllerName, { body });
};

const signReport = async (body: SignHoursReportRequest): Promise<null> => {
  return Client('PATCH', `${controllerName}/sign`, { body });
};

const editReport = async (body: UpdateHoursReportRequest): Promise<null> => {
  return Client('PUT', controllerName, { body });
};

const deleteRaport = async (
  workerId: string,
  year: number,
  month: number
): Promise<null> => {
  return Client('DELETE', `${controllerName}/${workerId}/${year}/${month}`);
};

const sentRemarks = async (body: SentRemarksRequest): Promise<null> => {
  return Client('POST', `${controllerName}/remarks`, { body });
};

export {
  getReports,
  getReport,
  getNumberOfSignedReports,
  addReport,
  signReport,
  editReport,
  deleteRaport,
  sentRemarks,
};
