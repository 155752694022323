import React from 'react';
import './Logo.scss';
import { useNavigate } from 'react-router-dom';
import RockruitLogo from './RockruitLogo.svg';

interface ILogo {
  disableOnClick?: boolean;
}

const Logo = ({ disableOnClick = false }: ILogo) => {
  const navigate = useNavigate();
  return (
    <div
      onClick={() => {
        if (!disableOnClick) {
          navigate(-1);
        }
      }}
    >
      <img src={RockruitLogo} alt='Rockruit logo' className='logo__image' />
      <span className='logo__text'>ROCKRUIT</span>
    </div>
  );
};

export default Logo;
