import React, { useState, useEffect } from 'react';
import { getReports, addReport } from '../../api/Endpoints/HoursReports';
import { CreateReportRequest } from '../../types/HoursReports/CreateReportRequest';
import { getWorkers } from '../../api/Endpoints/Workers';
import CircularProgress from '@mui/material/CircularProgress';
import { HoursReport } from '../../types/HoursReports/HoursReport';
import './Admin.scss';
import { localStorageConstants } from '../../ConstantsAndEnums/LocalStorageConstants';
import { useNavigate } from 'react-router';
import Navigation from '../../Components/Navigation/Navigation';
import Header from '../../Components/Header/Header';
import FiltersAndSorting from '../../Components/FiltersAndSorting/FiltersAndSorting';
import ListOfReports from '../../Components/ListOfReports/ListOfReports';
import { updateWorkers } from '../../Redux/Reducers/WorkersReducer';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../Redux/store';
import { customToast } from '../../Common/CustomToast/CustomToast';

const Admin = () => {
  const [reports, setReports] = useState<HoursReport[]>([]);
  const [searchText, setSearchText] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const dispatch = useDispatch();
  const workers = useSelector((state: RootState) => state.workers).workers;

  const navigate = useNavigate();

  const getReportsFromApi = (params: URLSearchParams) => {
    getReports(params).then((data) => {
      setReports(data);
      setLoading(false);
    });
  };

  useEffect(() => {
    const url = localStorage.getItem(localStorageConstants.redirectUrl);
    if (url && window.location.pathname + window.location.search !== url) {
      localStorage.removeItem(localStorageConstants.redirectUrl);
      navigate(url);
    } else {
      getReportsFromApi(new URLSearchParams());

      getWorkers().then((data) => {
        dispatch(updateWorkers(data));
      });
    }
  }, []);

  const addReportn = (
    email: string,
    hours: string,
    year: number,
    month: number,
    deadline: string
  ) => {
    const getWorker = workers.find((obj) => obj.email === email);
    if (getWorker === undefined) return;

    const newReport: HoursReport = {
      name: getWorker.name,
      surname: getWorker.surname,
      workerId: getWorker.id,
      email: email,
      hours: hours,
      year: year,
      month: month,
      deadline: deadline,
      isSigned: false,
    };

    const newReportRequest: CreateReportRequest = {
      email: email,
      workerId: getWorker.id,
      hours: parseFloat(hours),
      year: year,
      month: month,
      deadline: deadline,
    };
    const newReports = [...reports, newReport];
    setReports(newReports);
    addReport(newReportRequest).then(() => {
      customToast.success('Dodano ewidencję');
    });
  };

  return (
    <>
      {!loading ? (
        <>
          <Navigation handleSearchReport={setSearchText} />
          <div className='admin'>
            <Header handleAddReport={addReportn} />
            <div>
              <FiltersAndSorting
                getReportsFromApi={getReportsFromApi}
                setReports={setReports}
              />
              <ListOfReports
                reports={reports.filter((report) =>
                  report.email.toLowerCase().includes(searchText.toLowerCase())
                )}
                setReports={setReports}
              />
            </div>
          </div>
        </>
      ) : (
        <div className='admin__spinner'>
          <CircularProgress size='20vh' />
        </div>
      )}
    </>
  );
};

export default Admin;
