import React, { useState } from 'react';
import ClearIcon from '@mui/icons-material/Clear';
import { AiOutlineCheck } from 'react-icons/ai';
import { MdOutlineModeEditOutline } from 'react-icons/md';
import { UserWorker } from '../../types/Workers/UserWorker';
import { editWorker, deleteWorker } from '../../api/Endpoints/Workers';
import './UserTile.scss';
import ConfirmationDialog from '../../Common/ConfirmationDialog/ConfirmationDialog';
import { customToast } from '../../Common/CustomToast/CustomToast';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../Redux/store';
import { updateSingleWorker } from '../../Redux/Reducers/WorkersReducer';

interface UserProps {
  user: UserWorker;
  handleDeleteUser: (id: string) => void;
}

const UserTile: React.FC<UserProps> = ({ user, handleDeleteUser }) => {
  const [edit, setEdit] = useState<boolean>(false);
  const [editName, setEditName] = useState<string>(user.name);
  const [editSecondName, setEditSecondName] = useState<string>(user.surname);
  const [editEmail, setEditEmail] = useState<string>(user.email);
  const [showDialog, setShowDialog] = useState<boolean>(false);

  const workers = useSelector((state: RootState) => state.workers).workers;
  const dispatch = useDispatch();

  const saveInputs = () => {
    const current = workers.find((item) => item.id === user.id);

    if (current === undefined) {
      return;
    }

    if (
      editEmail !== user.email &&
      workers.find((item) => item.email === editEmail) !== undefined
    ) {
      customToast.warning('Pracownik o takim mailu już istnieje');
      return;
    }

    const updateWorker: UserWorker = {
      email: editEmail,
      hoursReports: current.hoursReports,
      id: current.id,
      name: editName,
      surname: editSecondName,
    };

    dispatch(updateSingleWorker(updateWorker));
    editWorker(updateWorker).then(() => {
      customToast.success('Zaktualizowano dane pracownika');
    });
    setEdit(false);
  };

  return (
    <div>
      <div className='user-tile'>
        <div style={{ width: '25%' }}>
          {edit ? (
            <input
              className='user-tile__edit-input'
              value={editName}
              onChange={(e) => setEditName(e.target.value)}
            ></input>
          ) : (
            <div className='user-tile__field'>{user.name}</div>
          )}
        </div>
        <div style={{ width: '25%' }}>
          {edit ? (
            <input
              className='user-tile__edit-input'
              value={editSecondName}
              onChange={(e) => setEditSecondName(e.target.value)}
            ></input>
          ) : (
            <div className='user-tile__field'>{user.surname}</div>
          )}
        </div>
        <div style={{ width: '40%' }}>
          <div>
            {edit ? (
              <input
                className='user-tile__edit-input'
                value={editEmail}
                onChange={(e) => setEditEmail(e.target.value)}
              ></input>
            ) : (
              <div className='user-tile__field'>{user.email}</div>
            )}
          </div>
        </div>
        <div style={{ width: '10%' }}>
          {edit ? (
            <AiOutlineCheck
              className='user-tile__save-user'
              size={30}
              onClick={() => {
                saveInputs();
              }}
            >
              Save
            </AiOutlineCheck>
          ) : (
            <MdOutlineModeEditOutline
              className='user-tile__edit-user'
              size={30}
              onClick={() => {
                setEdit(true);
              }}
            >
              Edit
            </MdOutlineModeEditOutline>
          )}

          <ClearIcon
            onClick={() => setShowDialog(true)}
            className='user-tile__delete-icon'
            fontSize='large'
          />

          <ConfirmationDialog
            title='Czy na pewno chcesz usunąć pracownika?'
            body='Ta akcja jest nieodwracalna. Wszystkie ewidencje godzin pracy zostaną usunięte z bazy danych.'
            open={showDialog}
            handleClose={() => setShowDialog(false)}
            handleConfirm={() => {
              handleDeleteUser(user.id);
              deleteWorker(user.id).then(() => {
                customToast.success('Usunięto pracownika');
              });
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default UserTile;
