import React from 'react';
import { HoursReport } from '../../types/HoursReports/HoursReport';
import Report from '../Report/Report';
import './ListOfReports.scss';

interface IListOfReports {
  reports: HoursReport[];
  setReports: React.Dispatch<React.SetStateAction<HoursReport[]>>;
}

const ListOfReports = ({ reports, setReports }: IListOfReports) => {
  return (
    <div className='list-of-reports'>
      {reports.map((report, index) => (
        <Report
          hoursReport={report}
          reports={reports}
          setReports={setReports}
          key={index}
        />
      ))}
    </div>
  );
};

export default ListOfReports;
