import React from 'react';
import { TextField } from '@mui/material';
import './SearchBar.scss';

interface ISearchBar {
  handleSearchReport: React.Dispatch<React.SetStateAction<string>>;
}

const SearchBar: React.FC<ISearchBar> = ({ handleSearchReport }) => {
  return (
    <div className='searchbar'>
      <TextField
        fullWidth
        className='searchbar__border'
        id='outlined-name'
        autoFocus
        size='small'
        label='Szukaj'
        onChange={(event) => {
          handleSearchReport(event.target.value);
        }}
      />
    </div>
  );
};

export default SearchBar;
