import { useAuth0 } from '@auth0/auth0-react';
import { Button, Grid, Popover } from '@mui/material';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './User.scss';
import {
  Person,
  ArrowDropUp,
  ArrowDropDown,
  Login,
  Logout,
} from '@mui/icons-material';

const User = () => {
  const user = useAuth0();
  const { logout, loginWithRedirect, isAuthenticated } = useAuth0();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState<HTMLElement>();
  const [popoverOpen, setPopoverOpen] = useState(false);
  return (
    <div className='user'>
      <Button
        size='large'
        onClick={(e) => {
          setAnchorEl(e.currentTarget);
          setPopoverOpen(true);
        }}
        startIcon={<Person style={{ color: '#3e50f2', width: 15 }} />}
        endIcon={popoverOpen ? <ArrowDropUp /> : <ArrowDropDown />}
      >
        <span className='user__text'>{user.user?.name}</span>
      </Button>

      <Popover
        anchorEl={anchorEl}
        onClose={() => setPopoverOpen(false)}
        open={popoverOpen}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        style={{ width: '100%' }}
      >
        <Grid container direction='column' style={{ padding: 10 }}>
          <Grid item>
            <Button
              onClick={() => {
                navigate(`/workers`);
                setPopoverOpen(false);
              }}
              style={{ color: 'black' }}
            >
              <span className='user__text'>Pracownicy</span>
            </Button>
          </Grid>
          <Grid item>
            {isAuthenticated ? (
              <Button
                endIcon={<Logout style={{ color: '#3e50f2' }} />}
                onClick={() => logout({ returnTo: window.location.origin })}
                style={{ color: 'black' }}
              >
                <span className='user__text'>Wyloguj</span>
              </Button>
            ) : (
              <Button
                endIcon={<Login style={{ color: '#3e50f2' }} />}
                onClick={() => loginWithRedirect()}
                style={{ color: 'black' }}
              >
                <span className='user__text'>Zaloguj</span>
              </Button>
            )}
          </Grid>
        </Grid>
      </Popover>
    </div>
  );
};

export default User;
