import React, { useState } from 'react';
import CreateReport from '../CreateReport/CreateReport';
import { Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import GenerateReportModal from '../GenerateReportModal/GenerateReportModal';
import './Header.scss';
import { getReports } from '../../api/Endpoints/HoursReports';
import { customToast } from '../../Common/CustomToast/CustomToast';
import { URLParam } from '../../ConstantsAndEnums/URLParams';
import { Status } from '../../ConstantsAndEnums/Status';

interface IHeader {
  handleAddReport: (
    email: string,
    hours: string,
    year: number,
    month: number,
    deadline: string
  ) => void;
}

const Header = ({ handleAddReport }: IHeader) => {
  const [isOpenCreateHoursReport, setIsOpenCreateHoursReport] =
    useState<boolean>(false);
  const [isOPenGenerateFinalReport, setIsOPenGenerateFinalReport] =
    useState<boolean>(false);
  const [deadline, setDeadline] = useState<string | undefined>(undefined);

  return (
    <div className='header'>
      <div className='header__title-and-buttons'>
        <h3>Lista ewidencji godzin</h3>
        <div className='header__buttons'>
          <Button
            startIcon={<AddIcon />}
            size='medium'
            variant='contained'
            onClick={() => setIsOpenCreateHoursReport(true)}
            className='header__button'
          >
            Ewidencja godzin
          </Button>
          <Button
            startIcon={<AddIcon />}
            size='medium'
            variant='contained'
            onClick={() => setIsOPenGenerateFinalReport(true)}
            className='header__button'
          >
            Raport końcowy
          </Button>
        </div>
      </div>
      <CreateReport
        open={isOpenCreateHoursReport}
        handleClose={() => setIsOpenCreateHoursReport(false)}
        onSubmit={async (email, hours, year, month, deadline) => {
          const params = new URLSearchParams([
            [URLParam.Year, year.toString()],
            [URLParam.Month, month.toString()],
            [URLParam.Emails, email],
            [URLParam.Statuses, Status.Signed],
            [URLParam.Statuses, Status.During],
            [URLParam.Statuses, Status.Overdue],
          ]);

          const reports = await getReports(params);

          if (reports.length > 0) {
            customToast.warning(
              'Ewidencja za ten miesiąc została już utworzona'
            );
          } else {
            handleAddReport(email, hours, year, month, deadline);
            setIsOpenCreateHoursReport(false);
          }
        }}
        title='Ewidencja godziny - generowanie linku'
        confirmButtonText='Wyślij do podpisania'
        setDeadline={setDeadline}
        deadline={deadline}
      />
      <GenerateReportModal
        isOpen={isOPenGenerateFinalReport}
        handleClosing={() => setIsOPenGenerateFinalReport(false)}
      />
    </div>
  );
};

export default Header;
