import React, { useEffect, useState } from "react";
import { Routes, Route, useNavigate } from "react-router";
import Admin from "./Pages/Admin/Admin";
import SignHours from "./Pages/SignHours/SignHours";
import WorkersManagement from "./Pages/WorkersManagement/WorkersManagement";
import { localStorageConstants } from "./ConstantsAndEnums/LocalStorageConstants";
import NoAccess from "./Pages/NoAccess/NoAccess";

interface IMain {
  hasAccess: boolean;
}

const Main = ({ hasAccess }: IMain) => {
  const navigate = useNavigate();
  const [noAccess, setNoAccess] = useState<boolean>();

  useEffect(() => {
    const url = localStorage.getItem(localStorageConstants.redirectUrl);
    if (url && window.location.pathname + window.location.search !== url) {
      localStorage.removeItem(localStorageConstants.redirectUrl);
      navigate(url);
    }
    setNoAccess(!window.location.href.includes("sign-hours") && !hasAccess);
  }, []);

  if (noAccess === undefined) {
    return <></>;
  }

  if (noAccess) {
    return <NoAccess />;
  }

  return (
    <Routes>
      <Route path="/" element={<Admin />} />
      <Route path="sign-hours/:month/:year" element={<SignHours />} />
      <Route path="workers" element={<WorkersManagement />} />
    </Routes>
  );
};

export default Main;
