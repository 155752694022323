import { Alert, Box, Button, Snackbar } from '@mui/material';
import Modal from '@mui/material/Modal';
import React, { useState } from 'react';
import './GenerateReportModal.scss';
import { jsPDF } from 'jspdf';
import autoTable from 'jspdf-autotable';
import { getReports } from '../../api/Endpoints/HoursReports';
import YearAndMonthPicker from '../../Common/YearAndMonthPicker/YearAndMonthPicker';
import { Status } from '../../ConstantsAndEnums/Status';
import { getRobotoFont } from '../../Fonts/getRobotoFont';
import { HoursReport } from '../../types/HoursReports/HoursReport';
import ModalHeader from '../../Common/ModalHeader/ModalHeader';
import { toMonthName } from '../../Functions/ToMonthName';
import { URLParam } from '../../ConstantsAndEnums/URLParams';

interface IGenerateReportModal {
  isOpen: boolean;
  handleClosing: () => void;
}
const GenerateReportModal = ({
  isOpen,
  handleClosing,
}: IGenerateReportModal) => {
  const [datetime, setDatetime] = useState<Date | null>(
    new Date(new Date().getFullYear(), new Date().getMonth(), 1, 0, 0, 0)
  );

  const [isOpenSnackbar, setIsOpenSnackbar] = useState<boolean>(false);
  const [notSignedReports, setNotSignedReports] = useState<HoursReport[]>([]);

  const generateRaport = async () => {
    if (!datetime) {
      return;
    }

    const params = new URLSearchParams([
      [URLParam.Year, datetime.getFullYear().toString()],
      [URLParam.Month, datetime.getMonth().toString()],
      [URLParam.Statuses, Status.Signed],
      [URLParam.Statuses, Status.During],
      [URLParam.Statuses, Status.Overdue],
    ]);

    const reports = await getReports(params);

    const notSignedReports = reports.filter((item) => item.isSigned === false);
    setNotSignedReports(notSignedReports);

    if (notSignedReports.length > 0) {
      setIsOpenSnackbar(true);
      return;
    }

    const doc = new jsPDF({ orientation: 'portrait' });

    doc.addFileToVFS('Roboto.ttf', getRobotoFont());
    doc.addFont('Roboto.ttf', 'Roboto', 'normal');
    doc.setFont('Roboto');

    doc.text(
      `Ewidencja godzin za ${toMonthName(
        datetime.getMonth()
      )} ${datetime.getFullYear()} `,
      100,
      20,
      {
        align: 'center',
      }
    );

    autoTable(doc, {
      startY: 30,
      head: [
        ['Imię', 'Nazwisko', 'Liczba godzin', 'Data podpisania', 'Podpis'],
      ],
      body: reports.map((report) => {
        return [
          report.name,
          report.surname,
          report.hours,
          report.signedDatetime
            ? new Date(report.signedDatetime).toLocaleDateString() +
              ' ' +
              new Date(report.signedDatetime).toLocaleTimeString()
            : '',
          'Podpisano elektronicznie',
        ];
      }),
      theme: 'grid',
      styles: { font: 'Roboto' },
    });

    doc.save(
      `Ewidencja godzin za ${toMonthName(
        datetime.getMonth()
      )} ${datetime.getFullYear()}.pdf`
    );
  };

  return (
    <>
      <Snackbar
        open={isOpenSnackbar}
        autoHideDuration={20000}
        onClose={() => setIsOpenSnackbar(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert
          onClose={() => setIsOpenSnackbar(false)}
          severity='warning'
          sx={{ width: '100%' }}
        >
          <span style={{ whiteSpace: 'pre-line' }}>
            {' '}
            {`Nie wszyscy pracownicy podpisali ewidencję godzin${
              datetime
                ? ' za ' +
                  toMonthName(datetime.getMonth()) +
                  ' ' +
                  datetime.getFullYear()
                : ''
            }\n Pracownicy, którzy nie podpisali ewidencji:\n`}
            <li>
              {notSignedReports.map((item) => (
                <ul>{`${item.name} ${item.surname} (${item.email})`}</ul>
              ))}
            </li>
          </span>
        </Alert>
      </Snackbar>
      <Modal open={isOpen} onClose={handleClosing}>
        <Box>
          <div className='generate-report-modal'>
            <ModalHeader
              title='Raport końcowy'
              handleCloseModal={handleClosing}
            />

            <div className='generate-report-modal__content'>
              <YearAndMonthPicker
                value={datetime}
                onChange={(value) => setDatetime(value)}
              />
              <Button size='large' variant='contained' onClick={generateRaport}>
                Generuj raport
              </Button>
            </div>
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default GenerateReportModal;
