import {
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import YearAndMonthPicker from '../../Common/YearAndMonthPicker/YearAndMonthPicker';
import { SortBy } from '../../ConstantsAndEnums/SortBy';
import { SortingType } from '../../ConstantsAndEnums/SortingType';
import { Status } from '../../ConstantsAndEnums/Status';
import { RootState } from '../../Redux/store';
import { HoursReport } from '../../types/HoursReports/HoursReport';
import './FiltersAndSorting.scss';
import { useSelector } from 'react-redux';
import { URLParam } from '../../ConstantsAndEnums/URLParams';

interface IFiltersAndSorting {
  getReportsFromApi: (params: URLSearchParams) => void;
  setReports: React.Dispatch<React.SetStateAction<HoursReport[]>>;
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const FiltersAndSorting = ({
  getReportsFromApi,
  setReports,
}: IFiltersAndSorting) => {
  const [statuses, setStatuses] = useState<string[]>([
    Status.During,
    Status.Overdue,
  ]);
  const [emails, setEmails] = useState<string[]>([]);
  const [datetime, setDatetime] = useState<Date | null>(null);
  const [sortingBy, setSortingBy] = useState<string>(
    SortBy.Datetime.toString()
  );
  const [sortingType, setSortingType] = useState<string>(
    SortingType.Desceding.toString()
  );

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const workers = useSelector((state: RootState) => state.workers).workers;

  useEffect(() => {
    if (isLoading) {
      setIsLoading(false);
      return;
    }
    const paramArr: string[][] = [];

    if (statuses) {
      statuses.forEach((status) => {
        paramArr.push([URLParam.Statuses, status]);
      });
    }

    if (emails) {
      emails.forEach((email) => {
        paramArr.push([URLParam.Emails, email]);
      });
    }

    if (datetime) {
      const year = datetime.getFullYear().toString();
      const month = datetime.getMonth().toString();
      paramArr.push([URLParam.Year, year]);
      paramArr.push([URLParam.Month, month]);
    }

    if (sortingBy) {
      paramArr.push([URLParam.SortBy, sortingBy]);
    }

    if (sortingType) {
      paramArr.push([URLParam.SortingType, sortingType]);
    }

    const params = new URLSearchParams(paramArr);
    getReportsFromApi(params);
  }, [sortingBy, sortingType, datetime, emails, statuses]);

  return (
    <div className='filters-and-sorting'>
      <FormControl sx={{ width: '19%' }}>
        <InputLabel size='small' id='status-label'>
          Status
        </InputLabel>
        <Select
          labelId='status-label'
          id='status'
          multiple
          value={statuses}
          onChange={(e) => setStatuses(e.target.value as string[])}
          input={<OutlinedInput label='Tag' />}
          renderValue={(selected) => selected.join(', ')}
          MenuProps={MenuProps}
          size='small'
        >
          {Object.values(Status).map((status) => (
            <MenuItem id={status} value={status}>
              <Checkbox checked={statuses.indexOf(status) > -1} />
              <ListItemText primary={status} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <FormControl sx={{ width: '23%' }}>
        <InputLabel size='small' id='email-label'>
          Email
        </InputLabel>
        <Select
          labelId='email-label'
          id='email'
          multiple
          value={emails}
          onChange={(e) => setEmails(e.target.value as string[])}
          input={<OutlinedInput label='Tag' />}
          renderValue={(selected) => selected.join(', ')}
          MenuProps={MenuProps}
          size='small'
        >
          {workers &&
            workers.map((worker) => (
              <MenuItem id={worker.id} value={worker.email}>
                <Checkbox checked={emails.indexOf(worker.email) > -1} />
                <ListItemText primary={worker.email} />
              </MenuItem>
            ))}
        </Select>
      </FormControl>

      <div style={{ width: '25%' }}>
        <YearAndMonthPicker
          value={datetime}
          onChange={(value) => setDatetime(value)}
          size='small'
        />
      </div>

      <FormControl variant='outlined' sx={{ width: '14%' }}>
        <InputLabel id='sort-by-label'>Sortuj wg</InputLabel>
        <Select
          labelId='sort-by-label'
          id='sort-by'
          value={sortingBy.toString()}
          label='Age'
          onChange={(e) => setSortingBy(e.target.value)}
          size='small'
        >
          <MenuItem value={SortBy.Status}>Status</MenuItem>
          <MenuItem value={SortBy.Email}>Email</MenuItem>
          <MenuItem value={SortBy.Datetime}>Data</MenuItem>
        </Select>
      </FormControl>
      <FormControl variant='outlined' sx={{ width: '14%' }}>
        <InputLabel id='sort-label'>Sortuj</InputLabel>
        <Select
          labelId='sort-label'
          id='sort'
          value={sortingType.toString()}
          label='Age'
          onChange={(e) => setSortingType(e.target.value)}
          size='small'
        >
          <MenuItem value={SortingType.Asceding}>Rosnąco</MenuItem>
          <MenuItem value={SortingType.Desceding}>Malejąco</MenuItem>
        </Select>
      </FormControl>
    </div>
  );
};

export default FiltersAndSorting;
