import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import React from 'react';

interface IConfirmationDialog {
  open: boolean;
  handleConfirm: () => void;
  handleClose: () => void;
  title: string;
  body: string;
}

const ConfirmationDialog = ({
  open,
  handleConfirm,
  handleClose,
  title,
  body,
}: IConfirmationDialog) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
    >
      <DialogTitle id='alert-dialog-title'>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id='alert-dialog-description'>
          {body}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleConfirm} color='secondary'>
          Tak
        </Button>
        <Button onClick={handleClose} autoFocus>
          Anuluj
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationDialog;
