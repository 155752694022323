import axios, { Method } from 'axios';
import { customToast } from '../Common/CustomToast/CustomToast';
import data from '../config.json';

const applicationBase = data.BASE_URL;

const Client = async (
  method: Method,
  endpoint: string,
  { body }: any = {},
  params?: URLSearchParams
): Promise<any> => {
  try {
    const requestResult = await axios({
      method: method,
      url: `${applicationBase}/${endpoint}`,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      data: JSON.stringify(body),
      responseType: 'json',
      params: params,
    });

    return requestResult.data;
  } catch (error: any) {
    customToast.error(
      `Nie udało się zrealizować operacji. Błąd: ${error.response.status}`
    );
  }
};

export { Client };
