import React from 'react';
import { Button, Dialog } from '@mui/material';
import { Formik, Form } from 'formik';
import { TextField } from '@mui/material';
import './CreateUser.scss';
import { customToast } from '../../Common/CustomToast/CustomToast';
import ModalHeader from '../../Common/ModalHeader/ModalHeader';
import { useSelector } from 'react-redux';
import { RootState } from '../../Redux/store';

interface ICreateUser {
  open: boolean;
  handleClose: () => void;
  onSubmit: (firstName: string, lastName: string, email: string) => void;
}

const CreateUser = ({ open, handleClose, onSubmit }: ICreateUser) => {
  const workers = useSelector((state: RootState) => state.workers).workers;
  return (
    <>
      <Dialog open={open} onClose={handleClose}>
        <div className='create-user-modal'>
          <ModalHeader
            title='Dodaj pracownika'
            handleCloseModal={handleClose}
          />
          <Formik
            initialValues={{
              firstName: '',
              lastName: '',
              email: '',
            }}
            onSubmit={(values) => {
              if (
                workers.find((item) => item.email === values.email) !==
                undefined
              ) {
                customToast.warning('Pracownik o takim mailu już istnieje');
                return;
              }
              onSubmit(values.firstName, values.lastName, values.email);
            }}
          >
            {({ values, handleChange, handleBlur }) => (
              <Form className='create-user-modal__container'>
                <TextField
                  required
                  id='outlined-basic'
                  label='Imię'
                  variant='outlined'
                  name='firstName'
                  value={values.firstName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />

                <TextField
                  required
                  id='outlined-basic'
                  label='Nazwisko'
                  variant='outlined'
                  name='lastName'
                  value={values.lastName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />

                <TextField
                  required
                  type='email'
                  id='outlined-basic'
                  label='Email'
                  variant='outlined'
                  name='email'
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />

                <Button variant='contained' type='submit'>
                  Zapisz
                </Button>
              </Form>
            )}
          </Formik>
        </div>
      </Dialog>
    </>
  );
};

export default CreateUser;
