import React, { useEffect, useState } from 'react';
import { Button } from '@mui/material';
import { useAuth0 } from '@auth0/auth0-react';
import './SignHoursModal.scss';
import { sentRemarks, signReport } from '../../../api/Endpoints/HoursReports';
import { HoursReport } from '../../../types/HoursReports/HoursReport';
import { SignHoursReportRequest } from '../../../types/HoursReports/SignHoursReportRequest';
import { SentRemarksRequest } from '../../../types/HoursReports/SentRemarksRequest';
import { customToast } from '../../../Common/CustomToast/CustomToast';
import { toMonthName } from '../../../Functions/ToMonthName';
import { capitalizeFirstLetter } from '../../../Functions/CapitalizeFirstLetter';

interface IUserModal {
  open: boolean;
  onClose: () => void;
  report: HoursReport;
  setReport: React.Dispatch<React.SetStateAction<HoursReport | undefined>>;
  month: string;
  year: string;
}

const SignHoursModal = ({ open, onClose, report, month, year }: IUserModal) => {
  const user = useAuth0();
  const [overdue, setOverdue] = useState<boolean>(false);
  const [seconds, setSeconds] = useState<number>(0);
  const [minutes, setMinutes] = useState<number>(0);
  const [hours, setHours] = useState<number>(0);
  const [days, setDays] = useState<number>(0);
  const [remarks, setRemarks] = useState<string>();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const signHoursReportRequest: SignHoursReportRequest = {
    month: month,
    year: year,
    workerId: report.workerId,
  };

  const onClickSentRemarks = () => {
    if (!remarks) {
      return;
    }

    setRemarks('');

    const request: SentRemarksRequest = {
      month: month,
      year: year,
      remarks: remarks,
    };
    sentRemarks(request).then(() => {
      customToast.success('Zgłoszono uwagi');
    });
  };

  const calculateDeadline = () => {
    const countDate = new Date(report.deadline).getTime();
    const now = new Date().getTime();
    let difference = countDate - now;

    if (difference <= 0) {
      setOverdue(true);
    }

    difference = Math.abs(difference);

    const newDays = Math.floor(difference / 86400000);
    difference -= newDays * 86400000;

    const newHours = Math.floor(difference / 3600000);
    difference -= newHours * 3600000;

    const newMinutes = Math.floor(difference / 60000);
    difference -= newMinutes * 60000;

    const newSeconds = Math.floor(difference / 1000);

    setSeconds(newSeconds);
    setMinutes(newMinutes);
    setHours(newHours);
    setDays(newDays);
  };

  useEffect(() => {
    calculateDeadline();
    setIsLoading(false);
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      calculateDeadline();
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [report.deadline]);

  if (!open || isLoading) return null;
  return (
    <>
      <div className='sign-hours-modal'>
        <p>Ewidencja Godzin</p>
        <div className='sign-hours-modal__deadline'>
          <p>
            {overdue
              ? 'Przekroczono czas na podpisanie o:'
              : 'Pozostały czas na podpisanie:'}{' '}
          </p>
          <p
            className={
              overdue
                ? 'sign-hours-modal__deadline--overdue'
                : 'sign-hours-modal__deadline--pending'
            }
          >
            {days !== 0 && `${Math.abs(days)} dni `}{' '}
            {hours !== 0 && `${Math.abs(hours)} h `}{' '}
            {minutes !== 0 &&
              `${Math.abs(minutes)}
          minut`}{' '}
            {Math.abs(seconds)} s
          </p>
        </div>
        <p>
          Pracownik:
          <span className='sign-hours-modal__text--blue'>
            {user.user?.name}
          </span>
        </p>
        <p>
          Miesiąc:
          <span className='sign-hours-modal__text--blue'>
            {`${capitalizeFirstLetter(toMonthName(report.month))} ${
              report.year
            }`}
          </span>
        </p>
        <p>
          Liczba godzin:
          <span className='sign-hours-modal__text--blue'>{report.hours}</span>
        </p>
      </div>

      <div className='sign-hours-modal-bottom'>
        <div className='sign-hours-modal-bottom__comment'>
          <div>
            <textarea
              autoFocus
              onChange={(e) => setRemarks(e.target.value)}
              value={remarks}
            ></textarea>
          </div>
        </div>
        <div className='sign-hours-modal-bottom__approve'>
          <Button
            size='large'
            variant='contained'
            onClick={() => {
              onClickSentRemarks();
            }}
            disabled={remarks === undefined || remarks.length === 0}
          >
            Zgłoś uwagi
          </Button>
          <Button
            size='large'
            variant='contained'
            onClick={() => {
              signReport(signHoursReportRequest).then(() => {
                onClose();
              });
            }}
          >
            Potwierdź
          </Button>
        </div>
      </div>
    </>
  );
};

export default SignHoursModal;
