import * as React from "react";
import TextField from "@mui/material/TextField";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { pl } from "date-fns/locale";
import { DatePicker } from "@mui/x-date-pickers";

interface IDateAndTimePicker {
  value: string;
  onChange: (
    date: Date | null,
    keyboardInputValue?: string | undefined
  ) => void;
  setDeadline?: React.Dispatch<React.SetStateAction<string | undefined>>;
}

const DateAndTimePicker = ({
  value,
  onChange,
  setDeadline,
}: IDateAndTimePicker) => {
  return (
    <div>
      <LocalizationProvider adapterLocale={pl} dateAdapter={AdapterDateFns}>
        <DatePicker
          views={["year", "month", "day"]}
          label="Wybierz dzień"
          value={new Date(value)}
          onChange={(value) => {
            onChange(value);
            if (setDeadline) {
              setDeadline(value?.toISOString());
            }
          }}
          renderInput={(params) => <TextField {...params} fullWidth />}
        />
      </LocalizationProvider>
    </div>
  );
};

export default DateAndTimePicker;
