import React, { useEffect } from 'react';
import './NoAccess.scss';
import { useAuth0 } from '@auth0/auth0-react';

const NoAccess = () => {
 const {
    logout,
  } = useAuth0();

  useEffect(() => {
    setTimeout(() => {
        logout();
    }, 2000);
  }, [logout]);
  
  return (
    <div className='no-access'>
     <h1>Nieprawiłowe dane logowania. Brak uprawnień do wyświetlenia strony</h1>
     <h2>Zaraz nastąpi przekierowania do okna logowania</h2>
    </div>
  );
};

export default NoAccess;
