import React from 'react';
import './ModalHeader.scss';
import CloseIcon from '@mui/icons-material/Close';

interface IModalHeader {
  title: string;
  handleCloseModal: () => void;
}
const ModalHeader = ({ title, handleCloseModal }: IModalHeader) => {
  return (
    <div className='modal-header'>
      <p>{title}</p>
      <CloseIcon
        onClick={handleCloseModal}
        className='modal-header__close-icon'
      />
    </div>
  );
};

export default ModalHeader;
