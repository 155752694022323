import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import { BrowserRouter } from "react-router-dom";
import { localStorageConstants } from "./ConstantsAndEnums/LocalStorageConstants";
import { checkIfUserHasAccess } from "./api/Endpoints/Users";
import Main from "./Main";

const App = () => {
  const {
    isAuthenticated,
    isLoading,
    loginWithRedirect,
    getAccessTokenSilently,
  } = useAuth0();

  const [isAccessTokenSet, setIsAccessTokenSet] = useState<boolean>(false);
  const [isUserHasAccess, setIsUserHasAccess] = useState<boolean>();

  if (!isLoading && !isAuthenticated) {
    localStorage.setItem(
      localStorageConstants.redirectUrl,
      window.location.pathname + window.location.search
    );
    console.log(window.location.pathname + window.location.search);
    loginWithRedirect({ prompt: "select_account" });
  }

  const getAccessToken = async () => {
    const accessToken = await getAccessTokenSilently();

    axios.interceptors.request.use((config) => {
      if (config && config.headers) {
        config.headers["Authorization"] = `Bearer ${accessToken}`;
      }
      return config;
    });

    checkIfUserHasAccess().then((data) => {
      setIsAccessTokenSet(true);
      setIsUserHasAccess(data);
    });
  };

  useEffect(() => {
    if (!isAccessTokenSet && isAuthenticated) {
      getAccessToken();
    }
  }, [isAccessTokenSet, isAuthenticated]);

  useEffect(() => {
    document.title = "Ewidencja godzin";
  }, []);

  if (!isAccessTokenSet || isUserHasAccess === undefined) {
    return <></>;
  }

  return (
    <BrowserRouter>
      <Main hasAccess={isUserHasAccess} />
    </BrowserRouter>
  );
};

export default App;
