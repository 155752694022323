import React, { useEffect, useState } from 'react';
import {
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Dialog,
} from '@mui/material';
import { Formik, Form } from 'formik';
import './CreateReport.scss';
import DateAndTimePicker from '../../Common/DateAndTimePicker/DateAndTimePicker';
import YearAndMonthPicker from '../../Common/YearAndMonthPicker/YearAndMonthPicker';
import ModalHeader from '../../Common/ModalHeader/ModalHeader';
import { useSelector } from 'react-redux';
import { RootState } from '../../Redux/store';

interface ICreateReport {
  open: boolean;
  handleClose: () => void;
  onSubmit: (
    email: string,
    hours: string,
    year: number,
    month: number,
    deadline: string
  ) => void;
  title: string;
  confirmButtonText: string;
  emailSelectIsReadOnly?: boolean;
  email?: string;
  hours?: string;
  year?: number;
  month?: number;
  deadline: string | undefined;
  setDeadline?: React.Dispatch<React.SetStateAction<string | undefined>>;
}

const ITEM_HEIGHT = 57;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const CreateReport = ({
  onSubmit,
  open,
  handleClose,
  title,
  confirmButtonText,
  emailSelectIsReadOnly = false,
  email,
  hours,
  year,
  month,
  deadline,
  setDeadline,
}: ICreateReport) => {
  const [datetime, setDatetime] = useState<Date | null>(
    year && month ? new Date(year, month, 1) : new Date()
  );
  const workers = useSelector((state: RootState) => state.workers).workers;

  const defaultDeadline = new Date();
  defaultDeadline.setDate(new Date().getDate() + 5);

  useEffect(() => {
    if (year && month) {
      setDatetime(new Date(year, month, 1));
    }
  }, [year, month]);

  return (
    <>
      <Dialog open={open} onClose={handleClose}>
        <div className='create-report-modal'>
          <ModalHeader title={title} handleCloseModal={handleClose} />
          <Formik
            initialValues={{
              email: email ? email : '',
              hours: hours ? hours : '',
              year: datetime
                ? datetime.getFullYear()
                : new Date().getFullYear(),
              month: datetime ? datetime.getMonth() : new Date().getMonth(),
              deadline: deadline
                ? deadline
                : `${defaultDeadline.toISOString()}`,
            }}
            onSubmit={async (values) => {
              onSubmit(
                values.email,
                values.hours,
                values.year,
                values.month,
                values.deadline
              );
            }}
          >
            {({ values, handleChange, handleBlur, setFieldValue }) => (
              <Form className='create-report-modal__container'>
                <YearAndMonthPicker
                  value={datetime}
                  onChange={(value) => {
                    setDatetime(value);
                    setFieldValue('year', value?.getFullYear());
                    setFieldValue(
                      'month',
                      value ? value.getMonth() : undefined
                    );
                  }}
                />

                <FormControl fullWidth>
                  <InputLabel id='demo-simple-select-label'>Email</InputLabel>
                  <Select
                    required
                    labelId='demo-simple-select-label'
                    id='demo-simple-select'
                    value={values.email}
                    label='Email'
                    onChange={(event) => {
                      setFieldValue('email', event.target.value);
                    }}
                    MenuProps={MenuProps}
                    readOnly={emailSelectIsReadOnly}
                  >
                    {workers.map((element) => {
                      return (
                        <MenuItem value={element.email}>
                          {element.email}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>

                <div className='create-report-modal__pickers'>
                  <div>
                    <TextField
                      sx={{ width: '100%' }}
                      required
                      type='number'
                      id='outlined-basic'
                      label='Ilość Godzin'
                      variant='outlined'
                      name='hours'
                      value={values.hours}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      inputProps={{ step: 'any' }}
                    />
                  </div>
                  <div>
                    <DateAndTimePicker
                      onChange={(value) => setFieldValue('deadline', value)}
                      value={values.deadline}
                      setDeadline={setDeadline}
                    />
                  </div>
                </div>
                <Button variant='contained' type='submit'>
                  {confirmButtonText}
                </Button>
              </Form>
            )}
          </Formik>
        </div>
      </Dialog>
    </>
  );
};

export default CreateReport;
