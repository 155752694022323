import { TextField } from "@mui/material";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { pl } from "date-fns/locale";
import React from "react";

interface IYearAndMonthPicker {
  value: Date | null;
  onChange: (value: Date | null) => void;
  size?: "small" | "medium";
}
const YearAndMonthPicker = ({
  value,
  onChange,
  size = "medium",
}: IYearAndMonthPicker) => {
  return (
    <LocalizationProvider adapterLocale={pl} dateAdapter={AdapterDateFns}>
      <DatePicker
        views={["year", "month"]}
        label="Wybierz miesiąc"
        value={value}
        onChange={onChange}
        renderInput={(params) => (
          <TextField {...params} size={size} helperText={null} />
        )}
      />
    </LocalizationProvider>
  );
};

export default YearAndMonthPicker;
