import React from "react";
import ReactDOM from "react-dom";
import { Auth0 } from "./Auth0/Auth0";
import "./index.css";
import { Auth0Provider } from "@auth0/auth0-react";
import App from "./App";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Zoom } from "react-toastify";
import { store, persistor } from "./Redux/store";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { ThemeProvider } from "@mui/material/styles";
import { theme } from "./Styles/Theme";

ReactDOM.render(
  <Auth0Provider
    domain={Auth0.domain}
    clientId={Auth0.clientID}
    audience={Auth0.audience}
    scope={Auth0.scope}
    redirectUri={window.location.origin}
    cacheLocation="localstorage"
  >
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ToastContainer
          position="bottom-center"
          autoClose={4000}
          hideProgressBar={true}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          pauseOnHover
          theme="colored"
          transition={Zoom}
        />
        <ThemeProvider theme={theme}>
          <React.StrictMode>
            <App />
          </React.StrictMode>
        </ThemeProvider>
      </PersistGate>
    </Provider>
  </Auth0Provider>,
  document.getElementById("root")
);
