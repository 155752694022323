import { CreateWorkerRequest } from '../../types/Workers/CreateWorkerRequest';
import { UserWorker } from '../../types/Workers/UserWorker';
import { Client } from '../Client';

const controllerName = 'Workers';

const getWorkers = async (): Promise<UserWorker[]> => {
  return Client('GET', controllerName);
};

const addWorker = async (body: CreateWorkerRequest): Promise<UserWorker> => {
  return Client('POST', controllerName, { body });
};

const editWorker = async (body: UserWorker): Promise<null> => {
  return Client('PUT', controllerName, { body });
};

const deleteWorker = async (id: string): Promise<null> => {
  return Client('DELETE', `${controllerName}/${id}`);
};

export { getWorkers, addWorker, editWorker, deleteWorker };
