import React, { useEffect, useState } from 'react';
import Logo from '../../Common/Logo/Logo';
import { useParams } from 'react-router';
import { getReport } from '../../api/Endpoints/HoursReports';
import { HoursReport } from '../../types/HoursReports/HoursReport';
import './SignHours.scss';
import SignHoursModal from '../../Components/SignHours/SignHoursModal/SignHoursModal';
import Success from '../../Components/SignHours/Success/Success';

const SignHours = () => {
  const [open, setOpen] = useState(true);
  const [report, setReport] = useState<HoursReport>();
  const { month, year } = useParams();
  const [isLinkExpired, setIsLinkExpired] = useState<boolean>(false);

  useEffect(() => {
    year &&
      month &&
      getReport(year, (parseInt(month) - 1).toString()).then((data) => {
        setReport(data);
        if (!data) {
          setIsLinkExpired(true);
        }
      });
  }, [month, year]);

  return (
    <div className='sign-hours'>
      <Logo disableOnClick={true} />
      {report && month && year && (
        <>
          {open && (
            <div className='sign-hours__modal'>
              <SignHoursModal
                open={open}
                onClose={() => {
                  setOpen(false);
                }}
                report={report}
                setReport={setReport}
                month={(parseInt(month) - 1).toString()}
                year={year}
              />
            </div>
          )}
          {open === false && (
            <div className='sign-hours__success'>
              <Success report={report!} />
            </div>
          )}
        </>
      )}
      {isLinkExpired && (
        <div className='sign-hours__expired-text'>
          <h1>Link wygasł</h1>
        </div>
      )}
    </div>
  );
};

export default SignHours;
