import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UserWorker } from '../../types/Workers/UserWorker';

export interface IWorkerState {
  workers: UserWorker[];
}

const initialState: IWorkerState = {
  workers: [],
};

export const workersSlice = createSlice({
  name: 'Workers',
  initialState,
  reducers: {
    updateWorkers: (state, action: PayloadAction<UserWorker[]>) => {
      state.workers = action.payload;
    },
    updateSingleWorker: (state, action: PayloadAction<UserWorker>) => {
      state.workers = state.workers.map((item) => {
        if (item.email === action.payload.email) {
          item.name = action.payload.name;
          item.surname = action.payload.surname;
          item.email = action.payload.email;
          item.id = action.payload.id;
        }
        return item;
      });
    },
  },
});

export const { updateWorkers, updateSingleWorker } = workersSlice.actions;
export default workersSlice.reducer;
