import React, { useState } from 'react';
import CreateUser from '../CreateUser/CreateUser';
import { Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import './UserModal.scss';
import { useNavigate } from 'react-router';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

interface IUserModal {
  handleAddUser: (name: string, secondname: string, email: string) => void;
}

const UserModal = ({ handleAddUser }: IUserModal) => {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();

  return (
    <div className='user-modal'>
      <Button
        sx={{
          textTransform: 'none',
        }}
        startIcon={<ArrowBackIcon style={{ color: '#3E50F2' }} />}
        onClick={() => {
          navigate('/');
        }}
      >
        <h2> Lista Pracowników</h2>
      </Button>
      <Button
        startIcon={<AddIcon />}
        size='small'
        variant='contained'
        onClick={() => setIsOpen(true)}
      >
        Dodaj pracownika
      </Button>

      <CreateUser
        open={isOpen}
        handleClose={() => setIsOpen(false)}
        onSubmit={(firstName, lastName, email) => {
          handleAddUser(firstName, lastName, email);
          setIsOpen(false);
        }}
      />
    </div>
  );
};

export default UserModal;
